import React from "react";

import { buttonClassName } from "./button.styles";
import { ButtonProps } from "./button.types";

const Button: React.FC<ButtonProps> = (props) => {
  const {
    label,
    disabled,
    // icon,
    // iconSize = 24,
    isFull = false,
    type = "button",
    variant = "filled",
    customStyles,
    size = "medium",
    customSpace,
    onClick,
    inline = false,
  } = props;

  const space =
    (variant === 'filled' || variant === 'alert') && size === 'medium' 
      ? 37
      : variant === 'filled' && size === 'large' 
      ? 26
      : 38

  const buttonSpacingStyle = {
    paddingLeft: `${customSpace || space}px`,
    paddingRight: `${customSpace || space}px`,
  }

  // const renderIcon = icon && (
  //   <div className={buttonsVariant[variant].icon}>
  //     <Icon name={icon} size={iconSize} />
  //   </div>
  // )
  return (
    <div>
      <button
        type={type}
        onClick={onClick?.bind(null)}
        disabled={disabled}
        className={buttonClassName({
          variant,
          size,
          customStyles,
          isFull,
          inline,
        })}
        style={buttonSpacingStyle}
      >
        {/* {renderIcon} */}
        {label}
      </button>
    </div>
  );
};

export default Button;
