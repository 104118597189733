import { configureStore } from "@reduxjs/toolkit";

import { adminApi } from "./api/admin/admin.api";
import { authApi } from "./api/admin/auth.api";
import { usersApi } from "./api/admin/users.api";
import { webauthnApi } from "./api/webauthn.api";

const rootReducer = {
  [authApi.reducerPath]: authApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [webauthnApi.reducerPath]: webauthnApi.reducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      adminApi.middleware,
      usersApi.middleware,
      webauthnApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
