import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Input, Modal } from "@/components/ui";
import { useRegisterClientMutation } from "@/store/api/admin/admin.api";
import { ClientData } from "@/types/client.type";

import { AddClientBlockProps } from "./types";

const AddClientBlock: FC<AddClientBlockProps> = ({ total }) => {
  const { register, handleSubmit, reset } = useForm<ClientData>({
    defaultValues: { password: "", username: "" },
  });

  const [error, setError] = useState("");
  const [showModalAddUser, setShowModalAddUser] = useState(false);

  const [registerClient, { isLoading: registerLoading }] =
    useRegisterClientMutation();

  const registerUser = (data: ClientData) => {
    registerClient(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setShowModalAddUser(false);
          setError("");
          reset();
        }
      })
      .catch(({ data }) => setError(data.error));
  };

  return (
    <div className="flex justify-between mb-[15px]">
      <div>Всего клиентов: {total}</div>
      <Button
        label="Добавить пользователя"
        onClick={() => setShowModalAddUser(true)}
      />
      <Modal
        title="Добавить пользователя"
        value={showModalAddUser}
        setValue={setShowModalAddUser}
        confirmText="Сохранить"
        cancelText="Закрыть"
        onConfirm={handleSubmit(registerUser)}
        loading={registerLoading}
        confirmDisabled={registerLoading}
      >
        <div className="flex flex-col gap-[10px] w-full mb-[10px]">
          <Input label="Имя" {...register("username")} />
          <Input label="Пароль" {...register("password")} type="password" />
          {error && <span className="text-red">{error}</span>}
        </div>
      </Modal>
    </div>
  );
};

export default AddClientBlock;
