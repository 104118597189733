import React, { FC, ReactElement } from "react";

import useAuth from "@/hooks/use-auth";
import { adminRoutes } from "@/routes/admin";

import DecoratorRoute from "../decorator-route";

const AuthAdminRoute: FC<{ children: ReactElement }> = ({ children }) => {
  const auth = useAuth();

  return (
    <DecoratorRoute
      conditionalRedirect={{
        to: adminRoutes.main,
        condition: !!auth?.user?.id && auth.isAdmin,
        rememberLocation: true,
      }}
      loading={auth?.isFetching}
    >
      {children}
    </DecoratorRoute>
  );
};

export default AuthAdminRoute;
