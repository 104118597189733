import React from "react";
import { Outlet, Route } from "react-router-dom";

import AuthRoute from "@/hoc/routes/client/auth-route";
import { LoginPage } from "@/pages/login";

import { route } from "./route";

export const AuthRoutes = (
  <Route
    path={route.prefixAuth}
    element={
      <AuthRoute>
        <Outlet />
      </AuthRoute>
    }
  >
    <Route path={route.login} element={<LoginPage />} />
  </Route>
);
