import clsx from "clsx";
import React from "react";

import { IconProps } from "../icons.type";

const Eye = (props: IconProps) => {
  const { size, className } = props;
  return (
    <div className={clsx(className)}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M2 12s3-7 10-7s10 7 10 7s-3 7-10 7s-10-7-10-7"/>
          <circle cx="12" cy="12" r="3"/>
        </g>
      </svg>
    </div>
  );
};
export default Eye;
