import React from "react";
import { Outlet } from "react-router-dom";

import { Header } from "@/components/ui";

const AdminLayout = () => {
  return (
    <div className="h-full">
      <Header isAdmin={true} />
      <Outlet />
    </div>
  );
};

export default AdminLayout;
