import React, { createContext, useContext } from "react";
import { v4 as uuidV4 } from "uuid";

import {
  NotificationContext,
  NotificationItem,
  NotificationProviderKeys,
  NotificationProviderValues,
} from "@/components/ui/notification";

const notificationContext = createContext<NotificationProviderKeys>({});

export const useNotification = () => useContext(notificationContext);

export function useNotificationProvider({
  notifications,
  setNotifications,
}: NotificationProviderValues): NotificationProviderKeys {
  const createNotification = (notification: NotificationItem) => {

    setNotifications?.((prevState) => [
      { ...notification, id: uuidV4() },
      ...prevState,
    ]);
  };
  const removeNotification = (id: string) => {
    setNotifications?.((prevState) =>
      prevState.filter((notification) => notification.id !== id)
    );
  };

  return {
    createNotification,
    notifications,
    removeNotification,
  };
}

export const NotificationProvider = ({
  children,
  setNotifications,
  notifications,
}: NotificationContext) => {
  const provideNotifications: NotificationProviderKeys =
    useNotificationProvider({
      notifications,
      setNotifications,
    });
  return (
    <notificationContext.Provider value={provideNotifications}>
      {children}
    </notificationContext.Provider>
  );
};
