import React, { FC, ReactElement } from "react";

import useAuth from "@/hooks/use-auth";
import { route } from "@/routes/client";

import DecoratorRoute from "../decorator-route";

const AuthRoute: FC<{ children: ReactElement }> = ({ children }) => {
  const auth = useAuth();

  return (
    <DecoratorRoute
      conditionalRedirect={{
        to: route.main,
        condition: !!auth?.user?.id && !auth.isAdmin,
        rememberLocation: true,
      }}
      loading={auth?.isFetching}
    >
      {children}
    </DecoratorRoute>
  );
};

export default AuthRoute;
