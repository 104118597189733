import clsx from 'clsx'

import { ButtonVariant, SizeButton, StyleVariant } from './button.types'

const buttonSizes: Record<ButtonVariant, Record<SizeButton, string>> = {
  filled: { medium: 'py-[4px]', large: 'py-[16px]' },
}

export const buttonsVariant: Record<ButtonVariant, Record<StyleVariant, string>> = {
  filled: {
    bg: "bg-primary_100 border-[2px] border-primary_100 hover:bg-primary_50 disabled:bg-gray_100 disabled:cursor-not-allowed disabled:hover:opacity-70 hover:opacity-70 disabled:hover:border-gray_300",
    txt: "text-white disabled:text-gray_300 disabled:hover:text-gray_300",
    icon: "mr-[4px] ml-[-6px]",
  },
};

export const buttonClassName = ({
  customStyles,
  size,
  variant,
  isFull,
  inline = false,
}: {
  customStyles: string | undefined
  size: SizeButton
  variant: ButtonVariant
  isFull: boolean
  inline: boolean
}) =>
  clsx(
    customStyles || buttonsVariant[variant].bg,
    buttonsVariant[variant].txt,
    buttonSizes[variant][size],
    'text-paragraph_s',
    'transition',
    'no-underline',
    'tracking-wide',
    'rounded-[3px]',
    'inline-flex',
    'items-center',
    isFull && 'w-full justify-center',
    inline && 'truncate',
  )
