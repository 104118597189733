import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { Loading } from "@/components/ui";

import { DecoratorRouteProps } from "./types";

const DecoratorRoute: FC<DecoratorRouteProps> = (props) => {
  const { children, conditionalRedirect, loading = false } = props;
  const location = useLocation();

  if (loading) return <Loading fullScreen={true} />;

  if (conditionalRedirect?.condition) {
    return (
      <Navigate
        to={conditionalRedirect.to}
        state={conditionalRedirect?.rememberLocation ? { from: location } : {}}
        replace
      />
    );
  }

  return children;
};

export default DecoratorRoute;
