import React from "react";

import { PagesRouting } from "@/pages";

import { Notifications } from "./components/ui/notification";

function App() {
  return (
    <div className="h-[100vh]">
      <PagesRouting />
      <Notifications />
    </div>
  );
}

export default App;
