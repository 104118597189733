import clsx from 'clsx'
import React, { FC, useEffect, useRef, useState } from 'react'

import Button from '../button'
import Loading from '../loading'
import {
  getModalBodyClassName,
  getModalClassName,
  getModalCloseIconClassName,
  getModalFooterActionClassName,
  getModalFooterAdditionalCancelActionClassName,
  getModalFooterAdditionalConfirmActionClassName,
  getModalFooterAdditionalConfirmationClassName,
  getModalFooterAdditionalQuestionConfirmationClassName,
  getModalFooterClassName,
  getModalTitleClassName,
  getModalWrapperClassName,
} from './modal.style'
import { ModalProps } from './modal.type'

const Modal: FC<ModalProps> = (props) => {
  const {
    value,
    setValue,
    title,
    footerType = 'horizontal',
    size = 'medium',
    children,
    onConfirm,
    confirmText,
    confirmDisabled,
    confirmButtonVariant,
    cancelText,
    onCancel,
    onAdditionalConfirm,
    additionalCancelText = '',
    additionalConfirmationText = '',
    additionalConfirmationQuestionText = '',
    loading = false,
    onClosed,
    onBack,
    handleScroll,
    cancelDisabled = false,
    isFullHeight = false,
  } = props
  const ModalWrapperRef = useRef<HTMLDivElement | null>(null)
  const [confirmation, setConfirmation] = useState(false)
  const [showChildren, setShowChildren] = useState(true)


  function closeModal() {
    setValue(false)
    setTimeout(() => {
      setConfirmation(false)
      onClosed?.()
    }, 100) // think how to fix it
  }

  function clickActionModal(isPrimaryAction: boolean) {
    if (isPrimaryAction) {
      onConfirm?.()
    } else {
      if (onBack) {
        onBack()
        return
      }
      if (footerType === 'confirmation') {
        // closeModal()
        setConfirmation(true)
        return
      }
      closeModal()
    }
  }

  function additionalConfirm() {
    onAdditionalConfirm?.()
    closeModal()
  }

  function shouldShowAdditionalFooterAction() {
    return confirmation || (!confirmation && cancelText)
  }

  return (
    <div
      ref={ModalWrapperRef}
      onScroll={handleScroll}
      className={getModalWrapperClassName(value)}
      style={{ zIndex: 1001 }}
      onClick={(e) => e.target === ModalWrapperRef.current && closeModal()}
    >
      <div className={getModalClassName(size)}>
        {loading && <Loading zIndex={1001} />}
        <h3 className={getModalTitleClassName()}>
          {title}
        </h3>
        {showChildren && (
          <div className={getModalBodyClassName(isFullHeight)}>{children}</div>
        )}
        <div className={getModalFooterClassName(footerType)}>
          {shouldShowAdditionalFooterAction() && (
            <div className={getModalFooterActionClassName(footerType)}>
              {!confirmation && cancelText && (
                <Button
                  label={cancelText}
                  isFull
                  customSpace={18}
                  onClick={() =>
                    onCancel ? onCancel() : clickActionModal(false)
                  }
                  disabled={loading || cancelDisabled}
                />
              )}
              {confirmation && (
                <div
                  className={getModalFooterAdditionalConfirmationClassName()}
                >
                  <span
                    className={getModalFooterAdditionalQuestionConfirmationClassName()}
                  >
                    {additionalConfirmationQuestionText}
                  </span>
                  <div
                    className={getModalFooterAdditionalConfirmActionClassName()}
                  >
                    <Button
                      label={additionalConfirmationText}
                      isFull
                      disabled={loading}
                      onClick={additionalConfirm.bind(null)}
                    />
                  </div>
                  <div
                    className={getModalFooterAdditionalCancelActionClassName()}
                  >
                    <Button
                      label={additionalCancelText}
                      isFull
                      disabled={loading}
                      onClick={setConfirmation.bind(null, false)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {confirmText && (
            <div
              className={clsx(
                getModalFooterActionClassName(footerType),
                !shouldShowAdditionalFooterAction() && '!basis-[100%]',
              )}
            >
              <Button
                label={confirmText}
                isFull
                variant={confirmButtonVariant}
                customSpace={16}
                onClick={() => clickActionModal(true)}
                disabled={loading || confirmDisabled || confirmation}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
