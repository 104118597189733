import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Input, Modal } from "@/components/ui";
import { useRegisterAdminMutation } from "@/store/api/admin/users.api";
import { AdminData } from "@/types/users.type";

import { AddAdminBlockProps } from "./types";

const AddAdminBlock: FC<AddAdminBlockProps> = ({ total }) => {
  const { register, handleSubmit, reset } = useForm<AdminData>({
    defaultValues: { email: "", name: "", password: "" },
  });

  const [error, setError] = useState("");
  const [showModalAddUser, setShowModalAddUser] = useState(false);

  const [registerAdmin, { isLoading: registerLoading }] =
    useRegisterAdminMutation();

  const registerUser = (data: AdminData) => {
    registerAdmin(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setShowModalAddUser(false);
          setError('');
          reset()
        }
      })
      .catch(({ data }) => setError(data.error));
  };

  return (
    <div className="flex justify-between mb-[15px]">
      <div>Всего администраторов: {total}</div>
      <Button
        label="Добавить администратора"
        onClick={() => setShowModalAddUser(true)}
      />
      <Modal
        title="Добавить администратора"
        value={showModalAddUser}
        setValue={setShowModalAddUser}
        confirmText="Сохранить"
        cancelText="Закрыть"
        onConfirm={handleSubmit(registerUser)}
        loading={registerLoading}
        confirmDisabled={registerLoading}
      >
        <div className="flex flex-col gap-[10px] w-full mb-[10px]">
          <Input label="Имя" {...register("name")} />
          <Input label="Email" {...register("email")} type="email" />
          <Input label="Пароль" {...register("password")} type="password" />
          {error && <span className="text-red">{error}</span>}
        </div>
      </Modal>
    </div>
  );
};

export default AddAdminBlock;
