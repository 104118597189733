import clsx from 'clsx'
import React from 'react'

import { IconProps } from '../icons.type'

const Search = (props: IconProps) => {
  const { size, className } = props
  return (
    <div className={clsx(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M19 19l-6-6m2-5a7.002 7.002 0 01-9.679 6.467A7 7 0 1115 8z"
        ></path>
      </svg>
    </div>
  )
}

export default Search
