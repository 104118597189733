import clsx from "clsx";
import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useRef,
  useState,
} from "react";

import { getMask } from "@/utils/validation/mask";

import Icon from "../icon";
import { inputClassName, inputWrapperClassName } from "./input.styles";
import { InputProps } from "./input.types";

const Input = forwardRef(
  (props: InputProps, forwardedRef: ForwardedRef<HTMLInputElement>) => {
    const {
      defaultValue,
      replaceValue,
      label,
      error,
      onChange,
      onClick,
      type = "text",
      placeholder,
      disabled = false,
      icon,
      unit,
      onClickUnit,
      mask,
      onIconClick,
      onBeforeIconClick,
      beforeIcon,
      autoComplete = "on",
      min,
      max,
      afterContent,
      ...remindedProps
    } = props;

    const [isFocus, setIsFocus] = useState(false);
    const prevDefaultValue = useRef<string>();

    const inputProps = {
      className: inputClassName,
      type,
      placeholder,
      disabled,
      autoComplete,
      onChange: handleOnChange,
      onClick,
      min,
      max,
      ...remindedProps,
      onFocus: setIsFocus.bind(null, true),
      onBlur: setIsFocus.bind(null, false),
    };

    const renderLabel = label && (
      <label
        className={clsx(
          "block",
          "mb-1",
          "text-paragraph_xs",
          "text-gray_20",
          "truncate",
          disabled ? "text-gray_400" : "text-gray_600"
        )}
      >
        {label}
      </label>
    );

    const renderError = !!error && (
      <p className="text-paragraph_s text-red">
        <>{error}</>
      </p>
    );

    const handlerIconClick = () => {
      onIconClick?.();
    };

    const renderIcon = icon && (
      <div className="mx-[4px] cursor-pointer" onClick={handlerIconClick}>
        <Icon
          name={icon}
          size={24}
          className="text-gray_20 opacity-80 text-primary_400 hover:opacity-100"
        />
      </div>
    );

    const renderUnit = unit && (
      <div
        onClick={() => onClickUnit?.()}
        className="mx-[8px] text-gray_400 text-paragraph_l cursor-pointer"
      >
        {unit}
      </div>
    );

    const renderBeforeIcon = beforeIcon && (
      <div className="ml-[12px]" onClick={onBeforeIconClick?.bind(null)}>
        <Icon
          name={beforeIcon}
          size={18}
          className="text-gray_20 opacity-80 text-primary_400 hover:opacity-100"
        />
      </div>
    );

    const renderInput = (
      <input ref={forwardedRef} value={defaultValue} {...inputProps} />
    );

    const renderAfterContent = afterContent && (
      <div className="mr-[12px]">{afterContent}</div>
    );

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
      const value = event.target.value;
      if (mask && typeof replaceValue === "function") {
        event.target.value = getMask(
          replaceValue(value, prevDefaultValue.current),
          mask
        );
      } else if (typeof replaceValue === "function") {
        event.target.value = replaceValue(value, prevDefaultValue.current);
      } else if (mask) {
        event.target.value = getMask(value, mask);
      }
      onChange?.(event);
      prevDefaultValue.current = value;
    }

    return (
      <div className="w-full">
        {renderLabel}
        <div
          className={inputWrapperClassName({
            isFocus,
            disabled,
            error: !!error,
          })}
        >
          {renderBeforeIcon}
          {renderInput}
          {renderIcon}
          {renderUnit}
          {renderAfterContent}
        </div>
        {renderError}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
