import clsx from "clsx";

export function getHeaderStyles() {
  return clsx(
    "bg-gray_100",
    "w-full",
    "h-[70px]",
    "overflow-hidden",
    "flex",
    "items-center",
    "justify-between",
    "pr-[20px]",
    "gap-[10px]"
  );
}
