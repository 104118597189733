import React, { FC } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'

import { getLinkClassName, getLinkContainerClassName } from './link-tabs.style'
import { LinkTabPropsType } from './link-tabs.type'

export const LinkTabs: FC<LinkTabPropsType> = (props) => {
  const { data, borderEnd = false, minWidth = 138 } = props
  const [params] = useSearchParams()
  const currentTab = params.get('tab') || data[0].query || null
  const currentLink =
    params.get('link') ||
    data[0].query
      ?.match(/link=*\w*$/gm)?.[0]
      ?.match(/[^link=]\w*[^\s]/gm)
      ?.toString() ||
    null

  return (
    <div className={getLinkContainerClassName(borderEnd)}>
      {data.map(({ query, id, to, label }, index) => {
        const currentTo = query ? `${to}?tab=${query}` : to

        const matchedLink = query?.match(/link=*\w*$/gm)
        const queryLink = matchedLink?.[0]
          ?.match(/[^link=]\w*[^\s]/gm)
          ?.toString()

        return (
          <NavLink
            id={id}
            key={index + to}
            to={currentTo}
            style={{ minWidth: `${minWidth}px` }}
            className={({ isActive }) =>
              getLinkClassName(
                query
                  ? currentTab === query || currentLink === queryLink
                  : isActive,
              )
            }
            end
          >
            {label}
          </NavLink>
        )
      })}
    </div>
  )
}
export default LinkTabs
