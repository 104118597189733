import React, { FC, ReactElement } from "react";

import useAuth from "@/hooks/use-auth";
import { adminRoutes } from "@/routes/admin";

import DecoratorRoute from "../decorator-route";

const PrivateAdminRoute: FC<{ children: ReactElement }> = ({ children }) => {
  const auth = useAuth();

  return (
    <DecoratorRoute
      conditionalRedirect={{
        to: adminRoutes.login,
        condition:
          !auth?.user?.id || !(auth.isAdmin),
        rememberLocation: true,
      }}
      loading={auth?.isFetching}
    >
      {children}
    </DecoratorRoute>
  );
};

export default PrivateAdminRoute;
