import clsx from "clsx";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import { Icon, Input, Modal, Table } from "@/components/ui";
import {
  useDeleteAdminMutation,
  useUpdateAdminMutation,
} from "@/store/api/admin/users.api";
import { Admin, AdminData } from "@/types/users.type";
import { getDateInFormat } from "@/utils/date";

import DeleteUserModal from "../../../blocks/delete-user-modal/delete-user-modal";
import { AdminsTableProps } from "./types";

const AdminsTable: FC<AdminsTableProps> = ({ data, isLoading }) => {
  const [error, setError] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState<Admin | null>(null);

  const { register, handleSubmit } = useForm<AdminData>({
    values: {
      email: currentAdmin?.email || "",
      name: currentAdmin?.name || "",
      password: "",
    },
  });

  const [deleteAdmin, { isLoading: deleteLoading }] = useDeleteAdminMutation();
  const [updateAdmin, { isLoading: updateLoading }] = useUpdateAdminMutation();

  const setIcons = (admin: Admin) => {
    return (
      <div className={clsx("flex gap-[9px] justify-end")}>
        <div
          className="hover:text-primary_100"
          title="Обновить"
          onClick={(e) => {
            e.stopPropagation();
            setCurrentAdmin(admin);
            setShowUpdateModal(true);
          }}
        >
          <Icon name="Edit" />
        </div>
        <div
          className="hover:text-primary_100"
          title="Удалить"
          onClick={(e) => {
            e.stopPropagation();
            setShowDeleteModal(true);
            setCurrentAdmin(admin);
          }}
        >
          <Icon name="Trash" />
        </div>
      </div>
    );
  };

  const columns = [
    {
      label: "Имя",
      prop: "name",
    },
    {
      label: "Email",
      prop: "email",
    },
    {
      label: "",
      prop: "icons",
      width: 100,
    },
  ];

  function onUpdateAdmin(data: AdminData) {
    if (currentAdmin)
      updateAdmin({ params: { client_id: currentAdmin?.id }, body: data })
        .unwrap()
        .then((res) => res.success && setShowUpdateModal(false))
        .catch(({ data }) => setError(data.error));
  }

  function onRowTable(data: Admin) {
    setCurrentAdmin(data);
    setShowDetailModal(true);
  }

  function deleteUser(id: number) {
    deleteAdmin({ params: { client_id: id } })
      .unwrap()
      .then(({ success }) => {
        success && setShowDeleteModal(false);
        setCurrentAdmin(null);
      });
  }

  return (
    <>
      <Table
        rowClick={onRowTable}
        loading={isLoading}
        columns={columns}
        data={
          data?.length
            ? data.map((admin: Admin) => {
                const icons = setIcons(admin);
                return { ...admin, icons };
              })
            : []
        }
      />
      <Modal
        title="Изменить администратора"
        value={showUpdateModal}
        setValue={setShowUpdateModal}
        confirmText="Сохранить"
        cancelText="Закрыть"
        onConfirm={handleSubmit(onUpdateAdmin)}
        loading={updateLoading}
      >
        <div className="flex flex-col gap-[10px] w-full mb-[10px]">
          <Input label="Имя" {...register("name")} />
          <Input label="Email" {...register("email")} type="email" />
          <Input label="Пароль" {...register("password")} type="password" />
          {error && <span className="text-red">{error}</span>}
        </div>
      </Modal>
      <Modal
        title={`Администратор ${currentAdmin?.name || ""}`}
        value={showDetailModal}
        setValue={setShowDetailModal}
        confirmText="Закрыть"
        loading={!currentAdmin}
        onConfirm={() => setShowDetailModal(false)}
      >
        <div className="flex flex-col gap-[10px] w-full mb-[10px]">
          <p>Email: {currentAdmin?.email}</p>
          <p>
            Дата регистрации:{" "}
            {getDateInFormat(currentAdmin?.ctime, "DD.MM.YYYY")}
          </p>
        </div>
      </Modal>
      <DeleteUserModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        loading={!currentAdmin || deleteLoading}
        disabled={deleteLoading}
        username={currentAdmin?.name || ""}
        onDelete={() => currentAdmin && deleteUser(currentAdmin.id)}
      />
    </>
  );
};

export default AdminsTable;
