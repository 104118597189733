import clsx from "clsx";

import { TableProps } from "./table.type";

export function getTableClassName() {
  return clsx("relative", "flex", "flex-col", "h-full");
}

export function getTableHeaderWrapperClassName() {
  return clsx("w-full", "overflow-hidden", "shrink-0");
}

export function getTableHeaderClassName() {
  return clsx(
    "w-full",
    "table-fixed",
    "border-separate",
    "border-b",
    "border-b-gray_200",
    "text-gray_600"
  );
}

export function getTableCellClassName(
  isMobile = false,
  isShowMessage = false,
  rowClick = false
) {
  return clsx(
    "relative",
    "z-1",
    "text-paragraph_l",
    "text-ellipsis",
    "align-middle",
    "text-left",
    isMobile
      ? "py-[10px] last:pb-0 first:pt-0"
      : isShowMessage
      ? "px-[8px] pt-[8px]"
      : "p-[8px]",
    "first:rounded-l-[4px]",
    "last:rounded-r-[4px]",
    rowClick && "cursor-pointer"
  );
}

export function getCellClassName(end: boolean) {
  return clsx("box-border", "text-ellipsis", "break-words", end && "text-end");
}

export function getTableBodyWrapperClassName() {
  return clsx("relative", "overflow-hidden", "flex-1");
}

export function getTableBodyScrollbarWrapClassName() {
  return clsx("h-full", "overflow-auto");
}

export function getTableBodyClassName() {
  return clsx(
    "w-full",
    "table-fixed",
    "border-separate",
    "pt-[8px]",
    "text-gray_900"
  );
}

export function getTableRowClassName(
  backgroundStriping: boolean,
  index: number
) {
  return clsx(
    "hover:bg-gray_100",
    "transition-colors",
    backgroundStriping && index % 2 === 0 && "bg-gray_10"
  );
}

export function getMobileTableClassName(maxHeight: TableProps["maxHeight"]) {
  return clsx(maxHeight && `max-h-[${maxHeight}px]`, "overflow-y-auto");
}

export function getMobileTableRowClassName() {
  return clsx("mb-[16px]", "pb-[16px]", "border-b", "border-b-gray_200");
}

export function getMobileTableLabelClassName() {
  return clsx("text-paragraph_l", "text-gray_500", "mb-[4px]");
}
