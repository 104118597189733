import { Routes } from "@/types/route.type";

export const route: Record<Routes, string> = {
  get main() {
    return "/client";
  },
  get prefixAuth() {
    return this.main + "/auth";
  },
  get login() {
    return this.prefixAuth + "/login";
  },
};
