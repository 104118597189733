import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
} from "@simplewebauthn/typescript-types";

import {
  OptionsData,
  VerifyAuthenticationData,
  VerifyAuthResponse,
  VerifyRegistrationData,
  VerifyResponse,
} from "@/types/webauthn.type";

export const webauthnApi = createApi({
  reducerPath: "WebauthnQuery",
  tagTypes: ["Webauthn"],
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (build) => ({
    generateRegistrationOptions: build.query<
      PublicKeyCredentialCreationOptionsJSON,
      OptionsData
    >({
      query: (params) => ({
        url: "webauthn/generate-registration-options",
        params,
      }),
    }),
    verifyRegistrationResponse: build.mutation<
      VerifyResponse,
      VerifyRegistrationData
    >({
      query: ({ body, params }) => ({
        url: "webauthn/verify-registration-response",
        method: "POST",
        params,
        body,
      }),
    }),
    generateAuthenticationOptions: build.query<
      PublicKeyCredentialRequestOptionsJSON,
      OptionsData
    >({
      query: (params) => ({
        url: "webauthn/generate-authentication-options",
        params,
      }),
    }),
    verifyAuthenticationResponse: build.mutation<
      VerifyAuthResponse,
      VerifyAuthenticationData
    >({
      query: ({ body, params }) => ({
        url: "webauthn/verify-authentication-response",
        method: "POST",
        params,
        body,
      }),
    }),
  }),
});

export const {
  useLazyGenerateRegistrationOptionsQuery,
  useVerifyRegistrationResponseMutation,
  useLazyGenerateAuthenticationOptionsQuery,
  useVerifyAuthenticationResponseMutation,
} = webauthnApi;
