import React, { FC } from "react";

import { Modal } from "@/components/ui";

import { DeleteUserModalProps } from "./types";

const DeleteUserModal: FC<DeleteUserModalProps> = ({
  show,
  setShow,
  loading,
  disabled,
  onDelete, username
}) => {
  return (
    <Modal
      title=""
      value={show}
      setValue={setShow}
      confirmText="Да"
      cancelText="Отменить"
      loading={loading}
      onConfirm={onDelete}
      confirmDisabled={disabled}
    >
      <div className="flex flex-col gap-[10px] w-full mb-[10px]">
        Вы действительно хотите удалить пользователя {username}?
      </div>
    </Modal>
  );
};

export default DeleteUserModal;
