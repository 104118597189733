import React, { useState } from "react";

import { Paginator } from "@/components/ui";
import { useGetClientsListQuery } from "@/store/api/admin/admin.api";
import { ClientOrder, GetClientsData } from "@/types/admin.type";

import { AddClientBlock, ClientsTable, SearchClientsBlock } from "./blocks";
import { SearchFields } from "./blocks/search-clients/types";

const Clients = () => {
  const LIMIT = 20;
  const PAGE = 1;

  const [filters, setFilters] = useState<GetClientsData>({
    params: {
      page: PAGE,
      limit: LIMIT,
      orderby: ClientOrder.USERNAME_ASC,
    },
  });

  const { data, isLoading, refetch } = useGetClientsListQuery({
    params: filters.params,
  });

  function onSearch(data: SearchFields) {
    setFilters((filters) => ({
      params: { ...filters.params, username: data.username, page: PAGE },
    }));
  }

  function onSortChange(orderby: ClientOrder) {
    setFilters((filters) => ({
      params: { ...filters.params, orderby },
    }));
  }

  return (
    <div className="max-w-[1400px] p-[20px] my-0 mx-auto">
      <AddClientBlock total={data?.data.total || 0} />
      <SearchClientsBlock onSearch={onSearch} />
      <ClientsTable
        data={data?.data.items || []}
        isLoading={isLoading}
        refetchClients={refetch}
        onSortChange={onSortChange}
        currentOrder={filters.params.orderby}
      />
      <Paginator
        count={data?.data.total || 0}
        page={filters.params.page || PAGE}
        onChange={(page) =>
          setFilters((filters) => ({
            params: { ...filters.params, page },
          }))
        }
        limit={LIMIT}
      />
    </div>
  );
};

export default Clients;
