import cn from "clsx";

export const pageClassName = (isTrue: boolean) =>
  cn(
    "text-paragraph_m_regular",
    "w-[52px]",
    "inline-flex",
    "justify-center",
    "cursor-pointer",
    "transition",
    isTrue
      ? "text-primary_100"
      : "text-gray_900 hover:text-primary_100"
  );
