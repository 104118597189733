import React, { useState } from "react";

import { Paginator } from "@/components/ui";
import { useGetAdminsListQuery } from "@/store/api/admin/users.api";
import { GetAdminsData } from "@/types/users.type";

import { AddAdminBlock, AdminsTable, SearchAdminsBlock } from "./blocks";
import { SearchFields } from "./blocks/search-admins/types";

const Admins = () => {
  const LIMIT = 20;
  const PAGE = 1;

  const [filters, setFilters] = useState<GetAdminsData>({
    params: {
      page: PAGE,
      limit: LIMIT,
      username: "",
    },
  });
  const { data, isLoading } = useGetAdminsListQuery({ params: filters.params });

  function onSearch(data: SearchFields) {
    setFilters((filters) => ({
      params: { ...filters.params, username: data.username, page: PAGE },
    }));
  }

  return (
    <div className="max-w-[1400px] p-[20px] my-0 mx-auto">
      <AddAdminBlock total={data?.data.total || 0} />
      <SearchAdminsBlock onSearch={onSearch} />
      <AdminsTable data={data?.data.items || []} isLoading={isLoading} />
      <Paginator
        count={data?.data.total || 0}
        page={filters.params.page || PAGE}
        onChange={(page) =>
          setFilters((filters) => ({
            params: { ...filters.params, page },
          }))
        }
        limit={LIMIT}
      />
    </div>
  );
};

export default Admins;
