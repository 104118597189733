import clsx from "clsx";
import React from "react";

import { IconProps } from "../icons.type";

const SortDesc = (props: IconProps) => {
  const { size, className } = props;
  return (
    <div className={clsx(className)}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none">
          <path
            d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
          />
          <path
            fill="currentColor"
            d="M18 3.5A1.5 1.5 0 0 1 19.5 5v11.207l.268-.268a1.5 1.5 0 1 1 2.121 2.122l-2.828 2.828a1.5 1.5 0 0 1-2.122 0l-2.828-2.828a1.5 1.5 0 1 1 2.121-2.122l.268.268V5A1.5 1.5 0 0 1 18 3.5m-7 14a1.5 1.5 0 0 1 .144 2.993L11 20.5H4a1.5 1.5 0 0 1-.144-2.993L4 17.5zm2-7a1.5 1.5 0 0 1 .144 2.993L13 13.5H4a1.5 1.5 0 0 1-.144-2.993L4 10.5zm0-7a1.5 1.5 0 0 1 0 3H4a1.5 1.5 0 1 1 0-3z"
          />
        </g>
      </svg>
    </div>
  );
};
export default SortDesc;
