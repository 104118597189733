import React, { ReactElement } from "react";

import AuthProvider from "./auth-provider";
import NotificationsProvider from "./notifications-provider";
import RouterProvider from "./router-provider";
import StoreProvider from "./store-provider";

const WithProviders = ({ children }: { children: ReactElement }) => (
  <NotificationsProvider>
    <RouterProvider>
      <StoreProvider>
        <AuthProvider>{children}</AuthProvider>
      </StoreProvider>
    </RouterProvider>
  </NotificationsProvider>
);

export default WithProviders;
