import React, { FC } from "react";

import Login from "@/features/login";


const LoginPage: FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  return <Login isAdmin={isAdmin} />;
};

export default LoginPage;
