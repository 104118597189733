import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { AuthData, AuthResponse } from "@/types/auth.type";
import { ListResponse } from "@/types/responses.type";

import { adminPrefix, improvedFetchQuery } from "../api";

export const authApi = createApi({
  reducerPath: "AuthQuery",
  tagTypes: ["Auth"],
  baseQuery: improvedFetchQuery,
  endpoints: (build) => ({
    me: build.query<any, null>({
      query: () => ({
        url: adminPrefix + "/auth/me",
        method: "POST",
      }),

      transformResponse: (response) => {
        return (response as ListResponse<AuthResponse>).data;
      },
    }),
    login: build.query<ListResponse<AuthResponse>, AuthData>({
      query: (data) => ({
        url: adminPrefix + "/auth/login",
        method: "POST",
        body: data,
      }),
    }),
    logout: build.query({
      query: () => ({
        url: adminPrefix + "/auth/logout",
        method: "POST",
      }),
    }),
  }),
});

export const { useMeQuery, useLazyLoginQuery, useLazyLogoutQuery } = authApi;
