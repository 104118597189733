import { startRegistration } from "@simplewebauthn/browser";
import { RegistrationResponseJSON } from "@simplewebauthn/typescript-types";
import clsx from "clsx";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import { Icon, Input, Modal, Table } from "@/components/ui";
import { useNotification } from "@/hooks/use-notifications";
import {
  useDeleteClientMutation,
  useRemoveKeyForClientMutation,
  useUpdateClientMutation,
} from "@/store/api/admin/admin.api";
import {
  useLazyGenerateRegistrationOptionsQuery,
  useVerifyRegistrationResponseMutation,
} from "@/store/api/webauthn.api";
import { Client, ClientOrder } from "@/types/admin.type";
import { ClientData } from "@/types/client.type";

import DeleteUserModal from "../../../blocks/delete-user-modal/delete-user-modal";
import RemoveKeyModal from "../../../blocks/remove-key-modal/remove-key-modal";
import UserLoginsModal from "../../../blocks/user-logins-modal/user-logins-modal";
import { ClientsTableProps } from "./types";

const ClientsTable: FC<ClientsTableProps> = ({
  data,
  isLoading,
  refetchClients,
  onSortChange,
  currentOrder,
}) => {
  const { createNotification } = useNotification();

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentClient, setCurrentClient] = useState<Client | null>(null);
  const [error, setError] = useState("");
  const [showUserLoginsModal, setShowUserLoginsModal] = useState(false);
  const [showRemoveKeyModal, setShowRemoveKeyModal] = useState(false);

  const { register, handleSubmit } = useForm<ClientData>({
    values: {
      username: currentClient?.username || "",
      password: "",
    },
  });

  const [deleteClient, { isLoading: deleteLoading }] =
    useDeleteClientMutation();
  const [updateClient, { isLoading: updateLoading }] =
    useUpdateClientMutation();
  const [removeKey, { isLoading: removeKeyLoading }] =
    useRemoveKeyForClientMutation();
  const [generateRegistrationOptions] =
    useLazyGenerateRegistrationOptionsQuery();
  const [verifyRegistrationResponse] = useVerifyRegistrationResponseMutation();

  function addKeyForClient(username: string) {
    let regResp: RegistrationResponseJSON;

    generateRegistrationOptions({ username })
      .unwrap()
      .then(async (opts) => {
        try {
          regResp = await startRegistration(opts);
        } catch (err) {
          createNotification?.({
            variant: "fail",
            title: err + "",
          });
        }
        verifyRegistrationResponse({
          body: regResp,
          params: { username },
        })
          .unwrap()
          .then((res) => {
            if (res.verified) {
              refetchClients();
              createNotification?.({
                variant: "success",
                title: "Ключ успешно обновлен",
              });
            } else {
              createNotification?.({
                variant: "fail",
                title: res.msg,
              });
            }
          })
          .catch(({ data }) => setError(data.error));
      });
  }

  function deleteKeyForClient(clientId: number) {    
    removeKey(clientId)
      .unwrap()
      .then(() => {
        createNotification?.({
          variant: "success",
          title: "Ключ удален",
        });
      })
      .catch(({ data }) => {        
        createNotification?.({
          variant: "fail",
          title: data.error || data.detail,
        });
      })
      .finally(() => {
        setShowRemoveKeyModal(false);
      });
  }

  const setIcons = (client: Client) => {
    return (
      <div className={clsx("flex gap-[9px] justify-end")}>
        <div
          className="text-blue hover:text-primary_100"
          title="Посмотреть список авторизаций"
          onClick={(e) => {
            e.stopPropagation();
            setCurrentClient(client);
            setShowUserLoginsModal(true);
          }}
        >
          <Icon name="Eye" />
        </div>
        <div
          className="text-green hover:text-primary_100"
          title="Обновить ключ"
          onClick={(e) => {
            e.stopPropagation();
            addKeyForClient(client.username);
          }}
        >
          <Icon name="Key" />
        </div>
        <div
          className="text-red hover:text-primary_100"
          title="Удалить ключ"
          onClick={(e) => {
            e.stopPropagation();
            setCurrentClient(client);
            setShowRemoveKeyModal(true);
          }}
        >
          <Icon name="Key" />
        </div>
        <div
          className="hover:text-primary_100"
          title="Удалить пользователя"
          onClick={(e) => {
            e.stopPropagation();
            setShowDeleteModal(true);
            setCurrentClient(client);
          }}
        >
          <Icon name="Trash" />
        </div>
      </div>
    );
  };

  const setLabelWithSort = (
    title: string,
    orderAsc: ClientOrder,
    orderDesc: ClientOrder,
  ) => {
    return (
      <div className="flex items-center">
        {title}
        <div
          className={
            `cursor-pointer ml-2 ${currentOrder === orderDesc ? 'text-blue' : ''}`
          }
          onClick={() => onSortChange(orderDesc)}
        >
          <Icon name="SortDesc" />
        </div>
        <div
          className={
            `cursor-pointer ml-1 ${currentOrder === orderAsc ? 'text-blue' : ''}`
          }
          onClick={() => onSortChange(orderAsc)}
        >
          <Icon name="SortAsc" />
        </div>
      </div>
    );
  };

  const columns = [
    {
      label: setLabelWithSort('Имя пользователя', ClientOrder.USERNAME_ASC, ClientOrder.USERNAME_DESC),
      prop: "username",
    },
    {
      label: "Пароль",
      prop: "password",
    },
    {
      label: "Ключ",
      prop: "is_credentials",
      replacer: (is_credentials: boolean) => {
        if (is_credentials) {
          return <Icon name="CheckCircle" className="text-green" />;
        } else {
          return <Icon name="XCircle" className="text-red" />;
        }
      },
    },
    {
      label: setLabelWithSort('Дата входа', ClientOrder.LOGIN_TIME_ASC, ClientOrder.LOGIN_TIME_DESC),
      prop: "login_time",
      replacer: (login_time: string) => login_time ? new Date(login_time).toLocaleDateString() : '',
    },
    {
      label: setLabelWithSort('IP адрес', ClientOrder.IP_ADDRESS_ASC, ClientOrder.IP_ADDRESS_DESC),
      prop: "ip_address",
    },
    {
      label: "",
      prop: "icons",
      width: 100,
    },
  ];

  function deleteUser(id: number) {
    deleteClient(id)
      .unwrap()
      .then(({ success }) => {
        success && setShowDeleteModal(false);
        setCurrentClient(null);
      });
  }

  function onRowTable(data: Client) {
    setCurrentClient(data);
    setShowDetailModal(true);
  }

  function onUpdateClient(data: ClientData) {
    if (currentClient)
      updateClient({ params: { client_id: currentClient?.id }, body: data })
        .unwrap()
        .then((res) => res.success && setShowDetailModal(false))
        .catch(({ data }) => setError(data.error));
  }

  return (
    <>
      <Table
        rowClick={onRowTable}
        loading={isLoading || removeKeyLoading}
        columns={columns}
        data={
          data?.length
            ? data.map((client: Client) => {
                const icons = setIcons(client);
                return { ...client, icons };
              })
            : []
        }
      />
      <Modal
        title={`Клиент ${currentClient?.username || ""}`}
        value={showDetailModal}
        setValue={setShowDetailModal}
        confirmText="Сохранить изменения"
        cancelText="Закрыть"
        loading={!currentClient || updateLoading}
        onConfirm={handleSubmit(onUpdateClient)}
      >
        <div className="flex flex-col gap-[10px] w-full mb-[10px]">
          <div className="flex flex-col gap-[10px] w-full mb-[10px]">
            <Input label="Имя" {...register("username")} />
            <Input label="Пароль" {...register("password")} type="password" />
            {error && <span className="text-red">{error}</span>}
          </div>
        </div>
      </Modal>
      <DeleteUserModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        loading={!currentClient || deleteLoading}
        disabled={deleteLoading}
        username={currentClient?.username || ""}
        onDelete={() => currentClient && deleteUser(currentClient.id)}
      />
      <UserLoginsModal
        show={showUserLoginsModal}
        setShow={setShowUserLoginsModal}
        username={currentClient?.username || ""}
        client_id={currentClient?.id}
        onClosed={() => setCurrentClient(null)}
      />
      <RemoveKeyModal
        show={showRemoveKeyModal}
        setShow={setShowRemoveKeyModal}
        loading={!currentClient || removeKeyLoading}
        disabled={removeKeyLoading}
        username={currentClient?.username || ""}
        onDelete={() => currentClient && deleteKeyForClient(currentClient.id)}
      />
    </>
  );
};

export default ClientsTable;
