import { PaginationType } from "./common.type";

export type Client = {
  id: number;
  username: string;
  password: null;
  en: boolean;
  credentials: Credential[];
  ctime: string;
  atime: string | null;
  dtime: string | null;
};

export type Credential = {
  id: string;
  userId: number;
  publicKey: string;
  signCount: number;
  transports: string[];
};

export type AddKeyRequest = { body: Credential; id: number };

export type GetClientsData = { params: PaginationType & { username?: string, orderby?: ClientOrder } };

export enum ClientOrder {
  USERNAME_DESC = 'username',
  USERNAME_ASC = '-username',
  IP_ADDRESS_DESC = 'ip_address',
  IP_ADDRESS_ASC = '-ip_address',
  LOGIN_TIME_DESC = 'login_time',
  LOGIN_TIME_ASC = '-login_time',
}

export type ClientLoginItem = {
  id: number;
  client_id: number;
  ip_address: string;
  ctime: string;
};

export type GetClientLoginsData = { params: PaginationType & { client_id?: number } };
