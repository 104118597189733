import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { ListResponse, ListResponsePagination } from "@/types/responses.type";
import {
  Admin,
  AdminData,
  GetAdminsData,
  UpdateAdminData,
} from "@/types/users.type";

import { adminPrefix, improvedFetchQuery } from "../api";

export const usersApi = createApi({
  reducerPath: "UsersQuery",
  tagTypes: ["Users"],
  baseQuery: improvedFetchQuery,
  endpoints: (build) => ({
    getAdminsList: build.query<ListResponsePagination<Admin[]>, GetAdminsData>({
      query: ({ params }) => ({
        url: adminPrefix + "/",
        params,
      }),
      providesTags: (clientResponse) =>
        clientResponse
          ? [
              ...clientResponse.data.items.map(({ id }) => ({
                type: "Users" as const,
                id,
              })),
              "Users",
            ]
          : [],
    }),
    getAdminById: build.query<ListResponse<Admin>, number>({
      query: (id) => ({
        url: `${adminPrefix}/${id}`,
      }),
    }),
    registerAdmin: build.mutation<ListResponse<Admin>, AdminData>({
      query: (body) => ({
        url: adminPrefix + "/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteAdmin: build.mutation<
      ListResponse<Admin>,
      { params: { client_id: number } }
    >({
      query: ({ params }) => ({
        url: `${adminPrefix}/${params.client_id}`,
        method: "DELETE",
        params,
      }),
      invalidatesTags: ["Users"],
    }),
    updateAdmin: build.mutation<ListResponse<Admin>, UpdateAdminData>({
      query: ({ params, body }) => ({
        url: `${adminPrefix}/${params.client_id}/update`,
        method: "POST",
        body,
        params,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetAdminsListQuery,
  useLazyGetAdminByIdQuery,
  useRegisterAdminMutation,
  useDeleteAdminMutation,
  useUpdateAdminMutation,
} = usersApi;
