import React, { FC, useState } from "react";

import { Modal, Paginator,Table } from "@/components/ui";
import { useGetClientLoginsQuery } from "@/store/api/admin/admin.api";
import { GetClientLoginsData } from "@/types/admin.type";

import { UserLoginsModalProps } from "./types";

const UserLoginsModal: FC<UserLoginsModalProps> = ({
  show,
  setShow,
  username,
  client_id,
  onClosed,
}) => {
  const LIMIT = 15;
  const PAGE = 1;

  const [filters, setFilters] = useState<GetClientLoginsData>({
    params: {
      page: PAGE,
      limit: LIMIT,
    },
  });

  const { data, isFetching } = useGetClientLoginsQuery({
    params: { ...filters.params, client_id },
  }, { skip: !client_id });

  const columns = [
    {
      label: "Дата входа",
      prop: "ctime",
      replacer: (ctime: string) => ctime ? new Date(ctime).toLocaleString() : '',
    },
    {
      label: "IP адрес",
      prop: "ip_address",
    },
  ];

  return (
    <Modal
      title={`Авторизации клиента ${username}`}
      value={show}
      setValue={setShow}
      cancelText="Закрыть"
      loading={isFetching}
      isFullHeight
      onClosed={onClosed}
    >
      <Table
        columns={columns}
        data={
          data?.data?.items?.length
            ? data.data.items
            : []
        }
      />
      <Paginator
        count={data?.data.total || 0}
        page={filters.params.page || PAGE}
        onChange={(page) =>
          setFilters((filters) => ({
            params: { ...filters.params, page },
          }))
        }
        limit={LIMIT}
      />
    </Modal>
  );
};

export default UserLoginsModal;
