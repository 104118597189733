import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { AddKeyRequest, Client, ClientLoginItem, GetClientLoginsData,GetClientsData } from "@/types/admin.type";
import { ClientData, UpdateClientData } from "@/types/client.type";
import { ListResponse, ListResponsePagination } from "@/types/responses.type";
import { ClientRegistrationOptions } from "@/types/webauthn.type";

import { adminPrefix, improvedFetchQuery } from "../api";

export const adminApi = createApi({
  reducerPath: "AdminQuery",
  tagTypes: ["Admin"],
  baseQuery: improvedFetchQuery,
  endpoints: (build) => ({
    getClientsList: build.query<
      ListResponsePagination<Client[]>,
      GetClientsData
    >({
      query: ({ params }) => ({
        url: adminPrefix + "/clients/",
        params,
      }),
      providesTags: (clientResponse) =>
        clientResponse
          ? [
              ...clientResponse.data.items.map(({ id }) => ({
                type: "Admin" as const,
                id,
              })),
              "Admin",
            ]
          : [],
    }),
    getClientLogins: build.query<
      ListResponsePagination<ClientLoginItem[]>,
      GetClientLoginsData
    >({
      query: ({ params }) => ({
        url: `${adminPrefix}/clients/${params.client_id}/logs`,
        params,
      }),
      providesTags: (clientResponse) =>
        clientResponse
          ? [
              ...clientResponse.data.items.map(({ id }) => ({
                type: "Admin" as const,
                id,
              })),
              "Admin",
            ]
          : [],
    }),
    registerClient: build.mutation<ListResponse<Client>, ClientData>({
      query: (body) => ({
        url: adminPrefix + "/clients/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Admin"],
    }),
    updateClient: build.mutation<ListResponse<Client>, UpdateClientData>({
      query: ({ params, body }) => ({
        url: `${adminPrefix}/clients/${params.client_id}/update`,
        method: "POST",
        body,
        params,
      }),
      invalidatesTags: ["Admin"],
    }),
    deleteClient: build.mutation<ListResponse<Client>, number>({
      query: (id) => ({
        url: `${adminPrefix}/clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),
    getRegistrationOptions: build.query<ClientRegistrationOptions, number>({
      query: (id) => ({
        url: `${adminPrefix}/clients/${id}/get_registration_options`,
        method: "POST",
      }),
    }),
    addKeyForClient: build.mutation<string, AddKeyRequest>({
      query: ({ body, id }) => ({
        url: `${adminPrefix}/clients/${id}/add-key`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Admin"],
    }),
    removeKeyForClient: build.mutation<ListResponse<Client>, number>({
      query: (id) => ({
        url: `${adminPrefix}/clients/${id}/remove-key`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),
  }),
});

export const {
  useGetClientsListQuery,
  useRegisterClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useLazyGetRegistrationOptionsQuery,
  useAddKeyForClientMutation,
  useRemoveKeyForClientMutation,
  useGetClientLoginsQuery,
} = adminApi;
