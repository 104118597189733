import clsx from "clsx";

export function getLogoStyles() {
  return clsx(
    "bg-logo",
    "w-[120px]",
    "h-[120px]",
    "bg-[length:80%_80%]",
    "bg-no-repeat",
    "bg-center",
    "absolute",
    "top-0 left-0"
  );
}
