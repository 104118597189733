import clsx from "clsx";
import React from "react";

import { IconProps } from "../icons.type";

const Close = (props: IconProps) => {
  const { size, className } = props;
  return (
    <div className={clsx(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.293 4.294a1 1 0 011.414 0L10 8.587l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.415l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.708a1 1 0 010-1.414z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default Close;
