import { AdminRoutes } from "@/types/route.type";

export const adminRoutes: Record<AdminRoutes, string> = {
  get main() {
    return "/admin";
  },
  get clients() {
    return this.main + "/clients";
  },
  get prefixAuth() {
    return this.main + "/auth";
  },

  get login() {
    return this.prefixAuth + "/login";
  },
};
