import React, { FC } from "react";
import { useForm } from "react-hook-form";

import { Button, Input } from "@/components/ui";

import { SearchAdminsBlockProps, SearchFields } from "./types";

const SearchAdminsBlock: FC<SearchAdminsBlockProps> = ({ onSearch }) => {
  const { register, handleSubmit, watch, resetField } = useForm<SearchFields>();

  return (
    <div className="flex gap-[10px] mb-[15px]">
      <Input
        {...register("username")}
        placeholder="Поиск по имени"
        beforeIcon="Search"
        icon={watch().username ? "Close" : undefined}
        onIconClick={() => {
          resetField("username");
          onSearch({ username: "" });
        }}
      />
      <Button label="Найти" onClick={handleSubmit(onSearch)} />
    </div>
  );
};

export default SearchAdminsBlock;
