export const masks = {
  '#': /[0-9]/,
  A: /[a-z]/i,
  C: /[а-я]/i,
  X: /./,
  '?': /.?/,
}
/**
 *
 * @param {string} value
 * @param {string|Array<string | RegExp>} mask - mask for change value
 * @returns {string} changed value
 */
export function getMask(
  value: string,
  mask: string | (string | RegExp)[],
): string {
  let index = 0
  const maxIndex = value.length
  if (typeof mask === 'string') {
    return mask.replace(/./g, () => {
      if (index !== maxIndex && index < maxIndex) {
        if (value[index].match(masks[mask[index] as keyof typeof masks])) {
          return value[index++]
        }
      }
      return ''
    })
  } else {
    let cloneMask = [...mask]
    if (mask.includes('?')) {
      const indexes = cloneMask.reduce(
        (total: number[], curr, index) =>
          curr === '?' ? [...total, index] : [...total],
        [],
      )
      indexes.map((index) => {
        if (index !== 0) {
          cloneMask[index - 1] = new RegExp(
            `/${cloneMask[index - 1]}?/`.replace(/\//g, ''),
          )
        }
      })
      cloneMask = cloneMask.filter((item) => item !== '?')
    }
    let maskedValue = ''
    for (let i = 0; i < cloneMask.length; i++) {
      if (i !== maxIndex && i < maxIndex) {
        if (value[i].match(cloneMask[i])) {
          if (value[i].match(cloneMask[i])?.[0] === '') {
            cloneMask.splice(i, 1)
            return getMask(value, cloneMask)
          }
          maskedValue += value[i]
        } else break
      }
    }
    return maskedValue
  }
}
