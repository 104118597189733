import { startAuthentication } from "@simplewebauthn/browser";
import { AuthenticationResponseJSON } from "@simplewebauthn/typescript-types";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Error, Header, Input } from "@/components/ui";
import useAuth from "@/hooks/use-auth";
import { useNotification } from "@/hooks/use-notifications";
import { useLazyLoginQuery } from "@/store/api/admin/auth.api";
import {
  useLazyGenerateAuthenticationOptionsQuery,
  useVerifyAuthenticationResponseMutation,
} from "@/store/api/webauthn.api";

const Login: FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const { createNotification } = useNotification();

  const { register, handleSubmit, watch } = useForm();

  const [error, setError] = useState("");

  const auth = useAuth();
  const [onLoginQuery] = useLazyLoginQuery();
  const [generateAuthenticationOptions] =
    useLazyGenerateAuthenticationOptionsQuery();
  const [verifyAuthenticationResponse] =
    useVerifyAuthenticationResponseMutation();

  const loginAsClient = async () => {
    let authResp: AuthenticationResponseJSON;
    generateAuthenticationOptions({ username: watch().username })
      .unwrap()
      .then(async (opts) => {
        try {
          setError("");
          authResp = await startAuthentication(opts);
        } catch (err) {
          createNotification?.({
            variant: "fail",
            title: err + "",
          });
        }
        verifyAuthenticationResponse({
          body: authResp,
          params: { username: watch().username },
        })
          .unwrap()
          .then((res) => {
            setError("");
            if (res?.redirect_url) {
              window.open(res.redirect_url);
            } else {
              createNotification?.({
                variant: "fail",
                title: res.msg || "Произошла ошибка",
              });
            }
          })
          .catch(({ data }) => setError(data.error));
      })
      .catch(({ data }) => setError(data.error));
  };

  const loginAsAdmin = (data: any) => {
    onLoginQuery(data)
      .unwrap()
      .then(({ success, data }) => {
        setError("");
        success && auth?.login(data.me);
      })
      .catch(({ data }) => setError(data.error));
  };

  return (
    <div>
      <Header />
      <div className="mt-[100px] flex justify-center ">
        <form className="bg-white border border-gray_10 min-h-[260px] w-[400px] flex flex-col items-center py-[17px] px-[75px]">
          {isAdmin ? (
            <>
              <h2 className="font-arial text-black text-heading_h1 text-center mb-[16px]">
                Вход в систему
              </h2>
              <div className="flex flex-col gap-[10px] w-full">
                <Input label="Логин" {...register("email")} />
                <Input
                  label="Пароль"
                  {...register("password")}
                  type="password"
                />
                <Error error={error} />
              </div>
              <div className="flex flex-col items-center gap-[10px] mt-[10px]">
                <Button label="Вход" onClick={handleSubmit(loginAsAdmin)} />
                <Button label="Восстановление" />
              </div>
            </>
          ) : (
            <>
              <h2 className="font-arial text-black text-heading_h1 text-center mb-[40px]">
                Вход в систему
              </h2>
              <div className="mb-[15px]">
                <Input label="Имя" {...register("username")} />
                <Error error={error} />
              </div>
              <Button label="Войти" onClick={loginAsClient} />
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
