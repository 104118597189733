import React, { FC, ReactNode, useState } from "react";

import { NotificationProvideItem } from "@/components/ui/notification";
import { NotificationProvider } from "@/hooks/use-notifications";

const NotificationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<
    Array<NotificationProvideItem>
  >([]);

  return (
    <NotificationProvider
      notifications={notifications}
      setNotifications={setNotifications}
    >
      {children}
    </NotificationProvider>
  );
};

export default NotificationsProvider;
