import React from "react";
import { Outlet, Route } from "react-router-dom";

import AuthAdminRoute from "@/hoc/routes/admin/auth-admin-route";
import { LoginPage } from "@/pages/login";

import { adminRoutes } from "./route";

export const AuthAdminRoutes = (
  <Route
    path={adminRoutes.prefixAuth}
    element={
      <AuthAdminRoute>
        <Outlet />
      </AuthAdminRoute>
    }
  >
    <Route path={adminRoutes.login} element={<LoginPage isAdmin />} />
  </Route>
);
