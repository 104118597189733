import React from "react";
import { Navigate, Route, Routes } from "react-router";

import PrivateAdminRoute from "@/hoc/routes/admin/private-admin-route";
import { PrivateRoute } from "@/hoc/routes/client";
import DecoratorRoute from "@/hoc/routes/decorator-route";
import useAuth from "@/hooks/use-auth";
import { adminRoutes, AuthAdminRoutes } from "@/routes/admin";
import { AuthRoutes, route } from "@/routes/client";

import { AdminsPage, ClientsPage, DashboardPage } from "./admin/dashboard";
import AdminLayout from "./layout/admin";
import ClientLayout from "./layout/client";

export const PagesRouting = () => {
  const auth = useAuth();

  function getRedirectFromSlash() {
    return !auth?.user?.id
      ? route.login
      : auth.isAdmin
      ? adminRoutes.clients
      : route.main;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <DecoratorRoute loading={auth?.isFetching}>
            <Navigate to={getRedirectFromSlash()} />
          </DecoratorRoute>
        }
      />
      <Route path={route.main} element={<ClientLayout />}>
        <Route
          index
          element={
            <PrivateRoute>
              <div>Main</div>
            </PrivateRoute>
          }
        />
      </Route>
      {AuthRoutes}
      {AuthAdminRoutes}
      {/* ADMIN ROUTES */}
      <Route path={adminRoutes.main} element={<AdminLayout />}>
        <Route
          path={adminRoutes.main}
          element={
            <PrivateAdminRoute>
              <DashboardPage />
            </PrivateAdminRoute>
          }
        >
          <Route
            index
            element={
              <PrivateAdminRoute>
                <AdminsPage />
              </PrivateAdminRoute>
            }
          />
          <Route
            path={adminRoutes.clients}
            element={
              <PrivateAdminRoute>
                <ClientsPage />
              </PrivateAdminRoute>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};
