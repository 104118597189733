import clsx from 'clsx'

export function getLinkClassName(active: boolean) {
  return clsx(
    'relative',
    'h-full',
    'flex',
    'items-center',
    'transition-all',
    active
      ? 'text-primary_100 before:content-[""] before:absolute before:bottom-0 before:w-full before:h-[2px] before:rounded-t-[2px] before:bg-primary_100 justify-center pb-[4px]'
      : 'before:content-[""] before:absolute before:bottom-0 before:w-full before:h-[1px] before:rounded-t-[2px] before:bg-gray_200 text-gray_500 justify-center pb-[4px]',
  )
}

export function getLinkContainerClassName(borderEdn?: boolean) {
  return clsx('flex', 'gap-[10px]', borderEdn && 'h-full')
}
