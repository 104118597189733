import { AnyAction, bindActionCreators, ThunkAction } from "@reduxjs/toolkit";
import { PrefetchOptions } from "@reduxjs/toolkit/dist/query/core/module";
import {
  EndpointDefinition,
  QueryArgFrom,
  ResultTypeFrom,
} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { useMemo } from "react";
import { useDispatch } from "react-redux";

import { authApi } from "@/store/api/admin/auth.api";

const actions = {
  prefetchAuthApi: (
    endpointName: keyof typeof authApi.endpoints,
    arg: QueryArgFrom<
      Record<
        string,
        EndpointDefinition<any, any, any, any>
      >[keyof typeof authApi.endpoints]
    >,
    options: PrefetchOptions
  ): ThunkAction<void, any, any, AnyAction> => {
    return authApi.util.prefetch(endpointName, arg, options);
  },
  upsertQueryDataAuthApi: (
    endpointName: keyof typeof authApi.endpoints,
    args: QueryArgFrom<
      Record<
        string,
        EndpointDefinition<any, any, any, any>
      >[keyof typeof authApi.endpoints]
    >,
    value: ResultTypeFrom<
      Record<
        string,
        EndpointDefinition<any, any, any, any>
      >[keyof typeof authApi.endpoints]
    >
  ) => {
    return authApi.util.upsertQueryData(endpointName, args, value);
  },
};

export const useActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
};
