import React, { FC } from "react";

import { Button, Logo } from "@/components/ui";
import useAuth from "@/hooks/use-auth";
import { useLazyLogoutQuery } from "@/store/api/admin/auth.api";

import { getHeaderStyles } from "./header.style";

const Header: FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const auth = useAuth();

  const [logout] = useLazyLogoutQuery();

  const onLogout = () => {
    logout(null).then(({ isSuccess }) => {
      isSuccess && auth?.logout();
    });
  };

  return (
    <div className={getHeaderStyles()}>
      <Logo />
      <span className="text-white whitespace-nowrap text-heading_h2 ml-[120px]">
        Система управления и дистанционного мониторинга
      </span>
      {isAdmin && auth.user?.id && <Button label="Выйти" onClick={onLogout} />}
    </div>
  );
};
export default Header;
