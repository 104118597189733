import clsx from "clsx";
import React, { FC, Fragment, MouseEvent, useEffect, useRef } from "react";

import Loading from "../loading";
import {
  getCellClassName,
  getMobileTableClassName,
  getMobileTableLabelClassName,
  getMobileTableRowClassName,
  getTableBodyClassName,
  getTableBodyScrollbarWrapClassName,
  getTableBodyWrapperClassName,
  getTableCellClassName,
  getTableClassName,
  getTableHeaderClassName,
  getTableHeaderWrapperClassName,
  getTableRowClassName,
} from "./table.style";
import { TableColumns, TableData, TableProps } from "./table.type";

const Table: FC<TableProps> = (props) => {
  const {
    columns = [],
    data = [],
    maxHeight,
    rowClick,
    createRowMessage,
    loading,
    backgroundStriping = false,
  } = props;
  const headerRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!bodyRef.current) return;
    if (maxHeight) {
      bodyRef.current.style.maxHeight = maxHeight + "px";
    } else {
      bodyRef.current.style.maxHeight = "";
    }
  }, [bodyRef, maxHeight]);

  function scrollBodyTable() {
    if (headerRef.current && bodyRef.current) {
      headerRef.current.scrollLeft = bodyRef.current.scrollLeft;
    }
  }

  function getTableBody() {
    if (!data.length) {
      return (
        <tbody>
          <tr>
            <td className={getTableCellClassName()}>
              <div className={clsx(getCellClassName(false), "text-center")}>
                Нет данных
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
    return data.map((item, index) => {
      const rowMessage = createRowMessage?.(item);
      return (
        <tbody key={"body-" + index}>
          <tr className={getTableRowClassName(backgroundStriping, index)}>
            {columns.map((column, index) => (
              <td
                style={{ width: column.width }}
                className={getTableCellClassName(
                  false,
                  rowMessage?.isShowMessage,
                  !!rowClick
                )}
                colSpan={1}
                rowSpan={1}
                key={"td-body" + index}
                onClick={(event: MouseEvent<HTMLTableCellElement>) => {
                  if (column?.cellClick) {
                    column.cellClick(event, item);
                  } else {
                    return rowClick?.bind(null, item)?.();
                  }
                }}
              >
                <div className={getCellClassName(column.end || false)}>
                  {getItemData(column, item)}
                </div>
              </td>
            ))}
          </tr>
          {rowMessage?.isShowMessage && (
            <tr>
              <td
                className="text-red text-paragraph_m pl-[8px]"
                colSpan={columns.length}
              >
                {rowMessage?.message}
              </td>
            </tr>
          )}
        </tbody>
      );
    });
  }

  function getItemData(column: TableColumns, item: TableData) {
    return column.replacer
      ? column.replacer(
          column.prop === "defaultProp" ? item : item[column.prop]
        )
      : item[column.prop];
  }

  function getColumnLabel(column: TableColumns) {
    return column.replacerLabel
      ? column.replacerLabel(column.label)
      : column.label;
  }

  function getMobileTable() {
    return (
      <div className={getMobileTableClassName(maxHeight)}>
        {data.map((item, index) => (
          <div className={getMobileTableRowClassName()} key={"body-" + index}>
            {columns.map((column, index) => (
              <div
                className={getTableCellClassName()}
                key={"td-mobile" + index}
                onClick={rowClick?.bind(null, item)}
              >
                <div className={getMobileTableLabelClassName()}>
                  {getColumnLabel(column)}
                </div>
                <div className={getCellClassName(false)}>
                  {getItemData(column, item)}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={getTableClassName()}>
      {loading && <Loading />}
      <Fragment>
        <div ref={headerRef} className={getTableHeaderWrapperClassName()}>
          <table
            className={getTableHeaderClassName()}
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <colgroup>
              {columns.map((column, index) => (
                <col
                  key={"col-" + index}
                  width={column.width}
                  style={
                    column.minWidth ? { minWidth: column.minWidth + "px" } : {}
                  }
                />
              ))}
            </colgroup>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th
                    className={getTableCellClassName()}
                    style={{
                      cursor: column?.tableHeaderCellClick
                        ? "pointer"
                        : "default",
                      userSelect: column?.tableHeaderCellClick ? "none" : "text",
                    }}
                    colSpan={1}
                    rowSpan={1}
                    key={"th-" + index}
                    onClick={() => column.tableHeaderCellClick?.(data)}
                  >
                    <div className={getCellClassName(column.end || false)}>
                      {getColumnLabel(column)}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
          </table>
        </div>
        <div className={getTableBodyWrapperClassName()}>
          <div
            ref={bodyRef}
            className={getTableBodyScrollbarWrapClassName()}
            onScroll={scrollBodyTable.bind(null)}
          >
            <table
              className={getTableBodyClassName()}
              border={0}
              cellPadding={0}
              cellSpacing={0}
            >
              <colgroup>
                {!!data.length &&
                  columns.map((column, index) => (
                    <col
                      key={"col-body" + index}
                      width={column.width}
                      style={
                        column.minWidth
                          ? { minWidth: column.minWidth + "px" }
                          : {}
                      }
                    />
                  ))}
                {!data.length && <col />}
              </colgroup>
              {getTableBody()}
            </table>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default Table;
