import clsx from "clsx";

export const inputWrapperClassName = ({
  disabled,
  error,
  isFocus,
}: {
  disabled: boolean;
  error: boolean;
  isFocus: boolean;
}) =>
  clsx(
    "border",
    "text-gray_600",
    "placeholder-green-700",
    "text-paragraph_l",
    "font-regular",
    "rounded-[2px]",
    "block",
    "w-full",
    "inline-flex",
    "items-center",
    "transition",
    disabled
      ? "border-gray_300 bg-gray_50 cursor-not-allowed"
      : error
      ? "border-negative_red"
      : isFocus
      ? "outline outline-1"
      : "border-gray_30 hover:border-gray_400 "
  );

export const inputClassName = clsx(
  "border-none",
  "focus:border-none",
  "outline-0",
  "focus:outline-0",
  "w-full",
  'p-[3px]',
  "text-gray_900",
  "bg-gray_40",
  "disabled:bg-gray_50",
  "disabled:text-gray_400",
  "disabled:cursor-not-allowed"
);
