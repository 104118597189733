import React, { createContext, ReactNode, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useActions } from "@/hooks/use-actions";
import { useMeQuery } from "@/store/api/admin/auth.api";

type ProviderAuthContext = {
  token: string;
  user: any | null;
  setUser: (MeResponse: any) => void;
  login: (user: any) => void;
  logout: () => void;
  isFetching: boolean;
  isAnonymous: boolean;
  isOwner: boolean;
  isAdmin: boolean;
};

export const AuthContext = createContext<ProviderAuthContext>(null!);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading, isFetching } = useMeQuery(null, {
    refetchOnFocus: true,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { prefetchAuthApi, upsertQueryDataAuthApi } = useActions();

  function login() {
    prefetchAuthApi("me", null, { force: true });
    location.state?.from?.pathname &&
      navigate(location.state?.from?.pathname, { replace: true });
  }

  function logout() {
    prefetchAuthApi("me", null, { force: true });
  }

  function setUser(MeResponse: any) {
    upsertQueryDataAuthApi("me", null, MeResponse);
  }

  const value: ProviderAuthContext = {
    user: data?.me || null,
    token: data?.token || "",
    setUser,
    login,
    logout,
    isFetching: isLoading || isFetching,
    isAnonymous: false,
    isOwner: false,
    isAdmin: true,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
