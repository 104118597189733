import clsx from "clsx";
import React from "react";

import { IconProps } from "../icons.type";

const Check = (props: IconProps) => {
  const { size, className } = props;
  return (
    <div className={clsx(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16.001A8 8 0 0010 18zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default Check;
