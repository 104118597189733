import React, { useMemo } from "react";
import { Outlet } from "react-router";
import { v4 } from "uuid";

import { LinkTabs } from "@/components/ui";
import { adminRoutes } from "@/routes/admin";

const Dashboard = () => {
  const linkTabsData = useMemo(
    () => [
      {
        id: v4(),
        label: "Клиенты",
        to: adminRoutes.clients,
      },
      {
        id: v4(),
        label: "Администраторы",
        to: adminRoutes.main,
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-[10px] max-w-[1400px] pt-[32px] pl-[20px] pr-[20px] my-0 mx-auto">
        <LinkTabs data={linkTabsData} />
      </div>
      <div className="pt-[16px]">
        <Outlet />
      </div>
    </>
  );
};

export default Dashboard;
